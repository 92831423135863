import React, { useState } from 'react';
import { Alert, Snackbar, Button, Card, CardContent, CardHeader, TextField, CircularProgress } from '@mui/material';

import BalanceService from '../../services/balance.service';

function BalanceAndExchange() {
  const [balance, setBalance] = useState('');
  const [balanceFetched, setBalanceFetched] = useState(false);
  const [exchangeCompleted, setExchangeCompleted] = useState('');
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [loadingExchange, setLoadingExchange] = useState(false);

  // Fetch balance from API and set state
  const getBalance = async () => {
    setLoadingBalance(true);
    setExchangeCompleted('');
    try {
      let pygCurrency;
      await BalanceService.get()
        .then((response) => {
          pygCurrency = response.data.available.find((item) => item.currency === 'pyg');
          setBalance(pygCurrency.amount);
        })
        .catch((e) => {
          console.log(e);
        });
      if (pygCurrency.amount !== 0) {
        setBalanceFetched(true);
      }
    } catch (error) {
      console.error('Error fetching the balance:', error);
    } finally {
      setLoadingBalance(false);
    }
  };

  const exchangePYGtoUSD = async () => {
    setLoadingExchange(true);
    try {
      console.log('Exchanging PYG to USD...');
      await BalanceService.pygToUsd()
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            setExchangeCompleted('success');
          } else {
            setExchangeCompleted('error');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.error('Error during exchange:', error);
    } finally {
      setLoadingExchange(false);
    }
  };

  return (
    <div className="container">
      <header className="jumbotron">
        {exchangeCompleted !== '' && (
          <Snackbar open={exchangeCompleted !== ''} autoHideDuration={6000}>
            <Alert severity={exchangeCompleted} variant="filled" sx={{ mb: 1 }}>
              Money exchange {exchangeCompleted}!
            </Alert>
          </Snackbar>
        )}
        <Card style={{ maxWidth: '400px', margin: '20px auto' }}>
          <CardHeader title="Balance and Exchange" />
          <CardContent>
            {/* Display the balance as a disabled input */}
            <TextField
              label='Please click "Get Balance" first'
              value={balance}
              variant="outlined"
              disabled
              fullWidth
              style={{ marginBottom: '20px' }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={getBalance}
              fullWidth
              style={{ marginBottom: '10px' }}
              disabled={loadingBalance}
              endIcon={loadingBalance ? <CircularProgress size={24} color="inherit" /> : null}
            >
              Get Balance
            </Button>
            {/* Conditionally render the Exchange button */}
            {balanceFetched && (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={exchangePYGtoUSD}
                disabled={loadingExchange}
                endIcon={loadingExchange ? <CircularProgress size={24} color="inherit" /> : null}
              >
                Exchange from PYG to USD
              </Button>
            )}
          </CardContent>
        </Card>
      </header>
    </div>
  );
}

export default BalanceAndExchange;
