import React, { useState, useEffect } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

const formatAmounts = (data) => {
  return data.map((item) => ({
    ...item,
    subscription: (item.subscription / 100).toFixed(2),
    sales: (item.sales / 100).toFixed(2)
  }));
};

const IncomeGraph = ({ data }) => {
  const [formattedData, setFormattedData] = useState(formatAmounts(data));

  useEffect(() => {
    const formatted = formatAmounts(data);
    setFormattedData(formatted);
  }, [data]);

  const formatYAxis = (value) => {
    return `${Number(value).toFixed(2)}`;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={formattedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip />
        <Legend />
        <Bar dataKey="subscription" fill="#8884d8" activeBar={<Rectangle fill="green" stroke="green" />} />
        <Bar dataKey="sales" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="gold" />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

IncomeGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      subscription: PropTypes.number.isRequired,
      sales: PropTypes.number.isRequired
    })
  ).isRequired
};

export default IncomeGraph;
