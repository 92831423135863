import axios from 'axios';

export const endpoints = {
  api: process.env.REACT_APP_API_URL,
  adminBase: 'admin/',
  palettes: 'palettes/',
  sections: 'sections/'
};

const axiosConfig = {
  withCredentials: true
};

// Palettes
// This is not an admin only endpoint
const getAllPalettes = () => {
  return axios.get(endpoints.api + endpoints.palettes, axiosConfig);
};

const createPalette = (paletteData) => {
  return axios.post(endpoints.api + endpoints.adminBase + endpoints.palettes, paletteData, axiosConfig);
};

const updatePalette = (paletteData) => {
  return axios.post(endpoints.api + endpoints.adminBase + endpoints.palettes + '/' + paletteData.id, paletteData, axiosConfig);
};

const deletePalette = (paletteId) => {
  return axios.delete(endpoints.api + endpoints.adminBase + endpoints.palettes + '/' + paletteId, axiosConfig);
};

// Sections
const getAllSections = () => {
  return axios.get(endpoints.api + endpoints.adminBase + endpoints.sections, axiosConfig);
};

const createSection = (sectionData) => {
  return axios.post(endpoints.api + endpoints.adminBase + endpoints.sections, sectionData, axiosConfig);
};

const toggleSectionVisibility = (sectionId) => {
  return axios.post(endpoints.api + endpoints.adminBase + endpoints.sections + '/' + sectionId + '/toggleVisibility', {}, axiosConfig);
};

const updateSection = (id, sectionData) => {
  return axios.post(endpoints.api + endpoints.adminBase + endpoints.sections + '/' + id, sectionData, axiosConfig);
};

const WebsiteBuilderService = {
  getAllPalettes,
  createPalette,
  updatePalette,
  deletePalette,
  getAllSections,
  createSection,
  toggleSectionVisibility,
  updateSection
};

export default WebsiteBuilderService;
