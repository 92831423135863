import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Login from './Login';
import TwoFactorAuth from './auth/TwoFA';
import Dashboard from './Dashboard';
import Accounts from './accounts/Accounts';
import AccountProfile from './accounts/AccountProfile';
import Products from './products/Products';
import PendingProducts from './pendingProducts/PendingProducts';
import BalanceAndExchange from './utilities/BalanceAndExchange';
import Utms from './utilities/Utms';
import Plans from './Plans';
import Sections from './websiteBuilder/Sections';
import Palettes from './websiteBuilder/Palettes';

const AllLinks = () => {
  const { isLoggedIn } = useAuth();
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/accounts/:accountId" element={<AccountProfile />} />
      <Route path="/accounts/:accountId/products" element={<Products />} />
      <Route path="/pendingProducts" element={<PendingProducts />} />
      <Route path="/utilities/stripeBalance" element={<BalanceAndExchange />} />
      <Route path="/utilities/utms" element={<Utms />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/websiteBuilder/sections" element={<Sections />} />
      <Route path="/websiteBuilder/palettes" element={<Palettes />} />
      <Route path="/twoFactorAuth" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <TwoFactorAuth />} />
      <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />} />
    </Routes>
  );
};

export default AllLinks;
