import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const UploadWidget = ({ onUpload }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
      },
      function (error, result) {
        if (result.event === 'success') {
          onUpload(result.info.secure_url);
        }
        // Handle the result or error here
      }
    );
  }, []);

  return <button onClick={() => widgetRef.current.open()}>Upload new / Replace</button>;
};

UploadWidget.propTypes = {
  onUpload: PropTypes.func.isRequired
};

export default UploadWidget;
