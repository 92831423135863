import axios from 'axios';
import { RequestParams } from '../helpers/utils';
export const endpoints = {
  api: process.env.REACT_APP_API_URL,
  adminBase: 'admin/',
  utms: 'utm/'
};

export interface UTM {
  id: number;
  source: string;
  medium: string;
  campaign: string;
  term?: string;
  content?: string;
  createdAt: string;
}

export interface UTMResponse {
  totalItems: number;
  utms: UTM[];
  totalPages: number;
  currentPage: number;
}

const getAll = (params: RequestParams) => {
  return axios.get<UTMResponse>(`${endpoints.api}${endpoints.adminBase}${endpoints.utms}/`, {
    params: params,
    withCredentials: true
  });
};

const UtmsService = {
  getAll
};

export default UtmsService;
