// Define interface for params object
export interface RequestParams {
  query?: string;
  page?: number;
  size?: number;
}

export function moneyFormatter(amount: number): string {
  const formattedNumber = (Number(amount) / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formattedNumber;
}

export function getRequestParams(searchQuery?: string, page?: number, pageSize?: number): RequestParams {
  const params: RequestParams = {};

  if (searchQuery) {
    params.query = searchQuery;
  }

  if (page) {
    params.page = page - 1;
  }

  if (pageSize) {
    params.size = pageSize;
  }

  return params;
}

export function a11yProps(index: number): {
  id: string;
  'aria-controls': string;
} {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
