import React, { useState, useEffect } from 'react';

import { Container, Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WebsiteBuilderService from '../../services/websiteBuilder.service';
import PaletteViewer from './palettes/PaletteViewer';

const DEFAULT_PALETTE = {
  id: 0,
  name: 'New Palette',
  colors: {
    primary: {
      background: '#FFFFFF',
      font: ['#000000'],
      buttons: ['#000000'],
      buttonText: ['#FFFFFF'],
      card: ['#F5F5F5'],
      cardText: ['#000000']
    },
    secondary: {
      background: '#FFFFFF',
      font: ['#000000'],
      buttons: ['#000000'],
      buttonText: ['#FFFFFF'],
      card: ['#F5F5F5'],
      cardText: ['#000000']
    },
    tertiary: {
      background: '#FFFFFF',
      font: ['#000000'],
      buttons: ['#000000'],
      buttonText: ['#FFFFFF'],
      card: ['#F5F5F5'],
      cardText: ['#000000']
    },
    quaternary: {
      background: '#FFFFFF',
      font: ['#000000'],
      buttons: ['#000000'],
      buttonText: ['#FFFFFF'],
      card: ['#F5F5F5'],
      cardText: ['#000000']
    }
  }
};

const Palettes = () => {
  const [palettes, setPalettes] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [paletteToDelete, setPaletteToDelete] = useState(null);

  useEffect(() => {
    const fetchPalettes = async () => {
      try {
        const response = await WebsiteBuilderService.getAllPalettes();
        if (response.data.success) {
          setPalettes(response.data.palettes);
        }
      } catch (error) {
        console.error('Error fetching palettes:', error);
      }
    };

    fetchPalettes();
  }, []);

  // Save
  const handleSavePalette = async (palette) => {
    try {
      let response;
      if (palette.id === 0) {
        // New palette
        response = await WebsiteBuilderService.createPalette(palette);
        if (response.data.success) {
          setPalettes(palettes.map((p) => (p.id === 0 ? response.data.palette : p)));
        }
      } else {
        // Existing palette
        response = await WebsiteBuilderService.updatePalette(palette);
        if (response.data.success) {
          setPalettes(palettes.map((p) => (p.id === palette.id ? response.data.palette : p)));
        }
      }
    } catch (error) {
      console.error('Error saving palette:', error);
    }
  };

  // Delete
  const handleDeleteClick = (paletteId) => {
    const palette = palettes.find((p) => p.id === paletteId);
    setPaletteToDelete(palette);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await WebsiteBuilderService.deletePalette(paletteToDelete.id);
      if (response.data.success) {
        setPalettes(palettes.filter((p) => p.id !== paletteToDelete.id));
      }
    } catch (error) {
      console.error('Error deleting palette:', error);
    }
    setDeleteConfirmOpen(false);
    setPaletteToDelete(null);
  };

  return (
    <Container maxWidth={false} sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Color Palettes
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            const newPalette = {
              ...DEFAULT_PALETTE,
              id: 0, // Backend will assign real ID
              name: 'New Palette'
            };
            setPalettes([...palettes, newPalette]);
          }}
        >
          New Palette
        </Button>
      </Box>

      <div style={{ padding: '24px' }}>
        <PaletteViewer palettes={palettes} callDeleteDialog={handleDeleteClick} savePalette={handleSavePalette} />
      </div>

      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete the palette &quot;{paletteToDelete?.name}?&quot;</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Palettes;
