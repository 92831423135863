import React, { useState, useEffect } from 'react';
import { getRequestParams } from '../../helpers/utils';
import UtmsService, { UTM } from '../../services/utm.service';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  IconButton,
  Divider,
  InputBase,
  SelectChangeEvent
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

interface UtmsProps {
  utms: UTM[];
}

const Utms: React.FC<UtmsProps> = () => {
  const [utms, setUtms] = useState<UTM[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const pageSizes = [10, 20, 30];

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);
  };

  const retrieveUtms = () => {
    setUtms([]);
    const params = getRequestParams(searchQuery, page, pageSize);

    UtmsService.getAll(params).then(
      (response) => {
        const { utms, totalPages } = response.data;
        setUtms(utms);
        setCount(totalPages);
      },
      (error) => {
        const _content = (error.response && error.response.data) || error.message || error.toString();

        setUtms(_content);
      }
    );
  };

  useEffect(retrieveUtms, [page, pageSize, searchQuery]);
  return (
    <div>
      <Typography variant="h6" component="h2" gutterBottom>
        UTM Tracking List
      </Typography>
      <Grid sx={{ mb: 1 }} container justifyContent="left">
        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}>
          <IconButton sx={{ p: '10px' }} aria-label="refresh" onClick={retrieveUtms}>
            <RefreshIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by source, medium, campaign, term or content"
            inputProps={{ 'aria-label': 'Search by source, medium, campaign, term or content' }}
            value={searchQuery}
            onChange={onChangeSearchQuery}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={retrieveUtms}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="UTM tracking table">
          <TableHead>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell>Medium</TableCell>
              <TableCell>Campaign</TableCell>
              <TableCell>Term</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {utms.map((utm) => (
              <TableRow key={utm.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{utm.source}</TableCell>
                <TableCell>{utm.medium}</TableCell>
                <TableCell>{utm.campaign}</TableCell>
                <TableCell>{utm.term || '-'}</TableCell>
                <TableCell>{utm.content || '-'}</TableCell>
                <TableCell>{utm.createdAt ? new Date(utm.createdAt).toLocaleDateString() : '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid sx={{ mt: 2 }} container>
        <FormControl size="small" sx={{ width: '6em' }}>
          <InputLabel id="demo-simple-select-label">Page size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageSize}
            label="Page size"
            onChange={handlePageSizeChange}
          >
            {pageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
          size="large"
        />
      </Grid>
    </div>
  );
};

export default Utms;
