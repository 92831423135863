import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Menu,
  MenuItem
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import WebsiteBuilderService from '../../services/websiteBuilder.service';
import SectionModal from './SectionModal';

const Sections = () => {
  const [sections, setSections] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [editingSection, setEditingSection] = useState(null);

  useEffect(() => {
    fetchSections();
  }, []);

  const fetchSections = () => {
    WebsiteBuilderService.getAllSections().then((response) => {
      if (response.data.success) {
        setSections(response.data.sections);
      }
    });
  };

  const handleOpen = () => {
    setEditingSection(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingSection(null);
  };

  const handleSubmit = async (sectionData) => {
    try {
      let response;
      if (editingSection) {
        response = await WebsiteBuilderService.updateSection(editingSection.id, sectionData);
      } else {
        response = await WebsiteBuilderService.createSection(sectionData);
      }

      if (response.data.success) {
        fetchSections();
        handleClose();
      }
    } catch (error) {
      console.error('Error saving section:', error);
    }
  };

  const handleToggleVisibility = async (section) => {
    try {
      const response = await WebsiteBuilderService.toggleSectionVisibility(section.id);
      if (response.data.success) {
        fetchSections();
      }
    } catch (error) {
      console.error('Error toggling section visibility:', error);
    }
  };

  const handleEdit = (section) => {
    setEditingSection(section);
    setOpen(true);
  };

  const handleMenuOpen = (event, section) => {
    setAnchorEl(event.currentTarget);
    setSelectedSection(section);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSection(null);
  };

  const sortedSections = [...sections].sort((a, b) => {
    return Number(a.hidden) - Number(b.hidden);
  });

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Website Sections
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          New Section
        </Button>
      </Box>

      <SectionModal open={open} onClose={handleClose} onSubmit={handleSubmit} section={editingSection} />

      <TableContainer
        component={Paper}
        sx={{
          mt: 3,
          maxHeight: 600,
          overflow: 'auto',
          position: 'relative'
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: 'background.paper' }}>Preview</TableCell>
              <TableCell sx={{ backgroundColor: 'background.paper' }}>Name</TableCell>
              <TableCell sx={{ backgroundColor: 'background.paper' }}>Type</TableCell>
              <TableCell sx={{ backgroundColor: 'background.paper' }}>Visible</TableCell>
              <TableCell sx={{ backgroundColor: 'background.paper' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSections.map((section) => (
              <TableRow key={section.section}>
                <TableCell>
                  <Box
                    component="img"
                    src={section.previewURL}
                    alt={`${section.name} preview`}
                    sx={{
                      width: 200,
                      height: 'auto',
                      objectFit: 'cover',
                      borderRadius: 1
                    }}
                  />
                </TableCell>
                <TableCell>{section.name}</TableCell>
                <TableCell>{section.type}</TableCell>
                <TableCell>{!section.hidden ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</TableCell>
                <TableCell>
                  <Button variant="contained" size="small" onClick={(e) => handleMenuOpen(e, section)} endIcon={<MoreVertIcon />}>
                    Actions
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          onClick={() => {
            handleEdit(selectedSection);
            handleMenuClose();
          }}
        >
          <EditIcon sx={{ mr: 1 }} fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleToggleVisibility(selectedSection);
            handleMenuClose();
          }}
        >
          <VisibilityIcon sx={{ mr: 1 }} fontSize="small" />
          Toggle Visibility
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Sections;
