import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  IconButton,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UploadWidget from '../utilities/UploadWidget';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: 'auto'
};

const SectionModal = ({ open, onClose, onSubmit, section }) => {
  const [formData, setFormData] = useState({
    previewURL: '',
    name: '',
    type: '',
    section: '',
    hidden: false,
    fields: []
  });

  const [currentField, setCurrentField] = useState({
    name: '',
    type: 'text'
  });

  // Initialize form when editing existing section
  useEffect(() => {
    if (section) {
      setFormData(section);
    } else {
      setFormData({
        previewURL: '',
        name: '',
        type: '',
        section: '',
        hidden: false,
        fields: []
      });
    }
  }, [section, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFieldInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentField((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const addField = () => {
    if (currentField.name) {
      setFormData((prev) => ({
        ...prev,
        fields: [...prev.fields, { ...currentField }]
      }));
      setCurrentField({
        name: '',
        type: 'text'
      });
    }
  };

  const removeField = (indexToRemove) => {
    setFormData((prev) => ({
      ...prev,
      fields: prev.fields.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box sx={modalStyle} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          {section ? 'Edit Section' : 'Add New Section'}
        </Typography>
        <Stack spacing={2}>
          {formData.previewURL ? (
            <Box
              component="img"
              src={formData.previewURL}
              alt="Section preview"
              sx={{
                width: '100%',
                height: 200,
                objectFit: 'cover',
                borderRadius: 1,
                border: '1px solid #ccc'
              }}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'grey.100',
                borderRadius: 1,
                border: '1px solid #ccc'
              }}
            >
              <Typography color="text.secondary">No preview image uploaded yet</Typography>
            </Box>
          )}
          <UploadWidget onUpload={(url) => setFormData({ ...formData, previewURL: url })} />
          <Typography id="modal-title" variant="subtitle2" sx={{ mb: 2 }}>
            This will be a preview of the section.
          </Typography>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            helperText="Give this section a descriptive name (e.g., 'Hero with Video Background'). This will be displayed on the editor"
          />

          <TextField
            fullWidth
            label="Section Identifier"
            name="section"
            value={formData.section}
            onChange={handleInputChange}
            required
            disabled={!!section}
            placeholder="Should match the component name"
            helperText="This field MUST match the React component name (e.g., 'HeroVideo')"
          />

          <FormControl fullWidth required>
            <InputLabel>Type</InputLabel>
            <Select name="type" value={formData.type} label="Type" onChange={handleInputChange}>
              <MenuItem value="section">Section</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                name="hidden"
                checked={!formData.hidden}
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: 'hidden',
                      value: !e.target.checked
                    }
                  })
                }
              />
            }
            label="Visible"
          />

          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Fields
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            • Fields order doesn&apos;t matter
            <br />
            • Field names are used like this: getContentByKey(&apos;NAME&apos;)
            <br />• Translations are defined by this name but must be configured on webapp
          </Typography>
          {formData.fields.map((field, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography flex={1}>
                {field.name} ({field.type})
              </Typography>
              <IconButton onClick={() => removeField(index)} size="small">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
            <TextField size="small" label="Field Name" name="name" value={currentField.name} onChange={handleFieldInputChange} />
            <FormControl size="small">
              <InputLabel>Type</InputLabel>
              <Select name="type" value={currentField.type} label="Type" onChange={handleFieldInputChange} sx={{ minWidth: 100 }}>
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="button">Button</MenuItem>
                <MenuItem value="image">Image</MenuItem>
              </Select>
            </FormControl>
            <IconButton onClick={addField} color="primary">
              <AddIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained">
              {section ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

SectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  section: PropTypes.object
};

export default SectionModal;
