import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  IconButton,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface Color {
  background: string;
  font: string[];
  buttons: string[];
  buttonText: string[];
  card: string[];
  cardText: string[];
}

interface Palette {
  id: number;
  name: string;
  colors: {
    primary: Color;
    secondary: Color;
    tertiary: Color;
    quaternary: Color;
  };
}

interface PaletteViewerProps {
  palettes: Palette[];
  callDeleteDialog: (paletteId: number) => void;
  savePalette: (palette: Palette) => void;
}

const PaletteViewer: React.FC<PaletteViewerProps> = ({ palettes: initialPalettes, callDeleteDialog, savePalette }) => {
  const [palettes, setPalettes] = useState(initialPalettes || []);

  React.useEffect(() => {
    if (initialPalettes && initialPalettes.length > 0) {
      setPalettes(initialPalettes);
    }
  }, [initialPalettes]);

  const [expandedPalette, setExpandedPalette] = useState<number | false>(false);
  const [editingPalette, setEditingPalette] = useState<number | null>(null);
  const [editingName, setEditingName] = useState<number | null>(null);

  const handleAccordionChange = (panelId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedPalette(isExpanded ? panelId : false);
  };

  const handleEdit = (paletteId: number) => {
    setEditingPalette(paletteId);
  };

  const handleSave = (paletteId: number) => {
    const palette = palettes.find((p) => p.id === paletteId);
    if (palette) {
      savePalette(palette);
      setEditingPalette(null);
    }
  };

  const handleColorChange = (paletteId: number, variant: keyof Palette['colors'], field: keyof Color, index: number, value: string) => {
    setPalettes((prevPalettes) =>
      prevPalettes.map((palette) =>
        palette.id === paletteId
          ? {
              ...palette,
              colors: {
                ...palette.colors,
                [variant]: {
                  ...palette.colors[variant],
                  [field]: Array.isArray(palette.colors[variant][field])
                    ? (palette.colors[variant][field] as string[]).map((color: string, i: number) => (i === index ? value : color))
                    : value
                }
              }
            }
          : palette
      )
    );
  };

  const handleAddColor = (paletteId: number, variant: keyof Palette['colors'], field: keyof Color) => {
    setPalettes((prevPalettes) =>
      prevPalettes.map((palette) =>
        palette.id === paletteId
          ? {
              ...palette,
              colors: {
                ...palette.colors,
                [variant]: {
                  ...palette.colors[variant],
                  [field]: Array.isArray(palette.colors[variant][field])
                    ? (palette.colors[variant][field] as string[]).concat('#000000')
                    : palette.colors[variant][field]
                }
              }
            }
          : palette
      )
    );
  };

  const handleRemoveColor = (paletteId: number, variant: keyof Palette['colors'], field: keyof Color, index: number) => {
    setPalettes((prevPalettes) =>
      prevPalettes.map((palette) =>
        palette.id === paletteId
          ? {
              ...palette,
              colors: {
                ...palette.colors,
                [variant]: {
                  ...palette.colors[variant],
                  [field]: Array.isArray(palette.colors[variant][field])
                    ? (palette.colors[variant][field] as string[]).filter((_: string, i: number) => i !== index)
                    : palette.colors[variant][field]
                }
              }
            }
          : palette
      )
    );
  };

  const handleNameChange = (paletteId: number, newName: string) => {
    setPalettes((prevPalettes) =>
      prevPalettes.map((palette) =>
        palette.id === paletteId
          ? {
              ...palette,
              name: newName
            }
          : palette
      )
    );
  };

  const renderColorCell = (color: string | string[], paletteId: number, variant: keyof Palette['colors'], field: keyof Color) => {
    const isEditing = editingPalette === paletteId;
    const isArray = Array.isArray(color);
    const colors = isArray ? color : [color];

    if (isEditing) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {colors.map((c, idx) => (
            <div key={idx} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div
                style={{
                  backgroundColor: c,
                  width: '20px',
                  height: '20px',
                  border: '1px solid #ccc'
                }}
              />
              <TextField
                value={c}
                onChange={(e) => handleColorChange(paletteId, variant, field, idx, e.target.value)}
                size="small"
                style={{ width: '100px' }}
              />
              {isArray && idx === colors.length - 1 && (
                <Tooltip title="Add color">
                  <IconButton size="small" onClick={() => handleAddColor(paletteId, variant, field)}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isArray && colors.length > 1 && (
                <Tooltip title="Remove color">
                  <IconButton size="small" onClick={() => handleRemoveColor(paletteId, variant, field, idx)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', gap: '4px' }}>
          {(isArray ? color : [color]).map((c, i) => (
            <div
              key={i}
              style={{
                backgroundColor: c,
                width: '20px',
                height: '20px',
                border: '1px solid #ccc'
              }}
            />
          ))}
        </div>
      );
    }
  };

  const renderColorFields = (palette: Palette, variant: keyof Palette['colors']) => {
    const color = palette.colors[variant];
    return (
      <>
        <TableCell>{renderColorCell(color.background, palette.id, variant, 'background')}</TableCell>
        <TableCell>{renderColorCell(color.font, palette.id, variant, 'font')}</TableCell>
        <TableCell>{renderColorCell(color.buttons, palette.id, variant, 'buttons')}</TableCell>
        <TableCell>{renderColorCell(color.buttonText, palette.id, variant, 'buttonText')}</TableCell>
        <TableCell>{renderColorCell(color.card, palette.id, variant, 'card')}</TableCell>
        <TableCell>{renderColorCell(color.cardText, palette.id, variant, 'cardText')}</TableCell>
      </>
    );
  };

  const renderPreview = (palette: Palette) => {
    const { primary, secondary, tertiary, quaternary } = palette.colors;

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
        {[primary, secondary, tertiary, quaternary].map((color, index) => (
          <Card key={index} style={{ backgroundColor: color.background, width: '350px' }}>
            <CardContent>
              {color.font.map((fontColor, fontIndex) => (
                <Typography key={fontIndex} style={{ color: fontColor }}>
                  Text {fontIndex + 1}
                </Typography>
              ))}
              {color.buttons.map((buttonColor, buttonIndex) => (
                <div key={buttonIndex} style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: buttonColor,
                      color: color.buttonText[buttonIndex] || color.buttonText[0],
                      marginTop: '8px',
                      marginRight: '8px',
                      boxShadow: 'none'
                    }}
                  >
                    Button {buttonIndex + 1}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: 'transparent',
                      color: buttonColor,
                      border: `1px solid ${buttonColor}`,
                      marginTop: '8px',
                      marginRight: '8px',
                      boxShadow: 'none'
                    }}
                  >
                    Outline Button {buttonIndex + 1}
                  </Button>
                </div>
              ))}
              {color.card.map((cardColor, cardIndex) => (
                <div
                  key={cardIndex}
                  style={{
                    backgroundColor: cardColor,
                    width: '100%',
                    height: '40px',
                    marginTop: '8px',
                    paddingTop: '7px'
                  }}
                >
                  <Typography style={{ color: color.cardText[cardIndex] || color.cardText[0] }}>Card Text {cardIndex + 1}</Typography>
                </div>
              ))}
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Variant</TableCell>
              <TableCell>Background</TableCell>
              <TableCell>Font</TableCell>
              <TableCell>Buttons</TableCell>
              <TableCell>Button Text</TableCell>
              <TableCell>Card</TableCell>
              <TableCell>Card Text</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {palettes.map((palette) => (
              <React.Fragment key={palette.id}>
                {(['primary', 'secondary', 'tertiary', 'quaternary'] as const).map((variant, index) => (
                  <TableRow key={`${palette.id}-${variant}`}>
                    {index === 0 && (
                      <TableCell rowSpan={4}>
                        {editingName === palette.id || palette.id === 0 ? (
                          <TextField
                            value={palette.name}
                            onChange={(e) => handleNameChange(palette.id, e.target.value)}
                            onBlur={() => {
                              setEditingName(null);
                              if (palette.id === 0) {
                                savePalette(palette);
                              }
                            }}
                            onClick={(e) => e.stopPropagation()}
                            size="small"
                            autoFocus
                          />
                        ) : (
                          <Typography onClick={() => setEditingName(palette.id)} style={{ cursor: 'pointer' }}>
                            {palette.name}
                          </Typography>
                        )}
                      </TableCell>
                    )}
                    <TableCell>{variant}</TableCell>
                    {renderColorFields(palette, variant)}
                    {index === 0 && (
                      <TableCell rowSpan={4}>
                        {editingPalette === palette.id ? (
                          <IconButton onClick={() => handleSave(palette.id)}>
                            <DoneIcon />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleEdit(palette.id)}>
                            <EditIcon />
                          </IconButton>
                        )}
                        {callDeleteDialog && (
                          <IconButton onClick={() => callDeleteDialog(palette.id)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={9}>
                    <Accordion expanded={expandedPalette === palette.id} onChange={handleAccordionChange(palette.id)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Preview</Typography>
                      </AccordionSummary>
                      <AccordionDetails>{renderPreview(palette)}</AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PaletteViewer;
