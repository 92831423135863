import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SignupGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="signups" fill="#8884d8" activeBar={<Rectangle fill="green" stroke="green" />} />
        <Bar dataKey="conversions" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="gold" />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

SignupGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      signups: PropTypes.number.isRequired,
      conversions: PropTypes.number.isRequired
    })
  ).isRequired
};

export default SignupGraph;
