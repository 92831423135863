import React from 'react';
import { Fragment, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import BuildIcon from '@mui/icons-material/Build';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WebIcon from '@mui/icons-material/Web';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PaletteIcon from '@mui/icons-material/Palette';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LinkIcon from '@mui/icons-material/Link';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { Link } from 'react-router-dom';

export const MainListItems = () => {
  const [openWebsite, setOpenWebsite] = useState(false);
  const [openUtilities, setOpenUtilities] = useState(false);

  const handleWebsiteClick = () => {
    setOpenWebsite(!openWebsite);
  };

  const handleUtilitiesClick = () => {
    setOpenUtilities(!openUtilities);
  };

  return (
    <Fragment>
      <ListItemButton component={Link} to={'/dashboard'}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton component={Link} to={'/accounts'}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Accounts" />
      </ListItemButton>
      <ListItemButton component={Link} to={'/pendingProducts'}>
        <ListItemIcon>
          <RuleFolderIcon />
        </ListItemIcon>
        <ListItemText primary="Pending products" />
      </ListItemButton>
      <ListItemButton onClick={handleUtilitiesClick}>
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText primary="Utilities" />
        {openUtilities ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openUtilities} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to="/utilities/stripeBalance" sx={{ pl: 4 }}>
            <ListItemIcon>
              <CurrencyExchangeIcon />
            </ListItemIcon>
            <ListItemText primary="Balance & Exchange" />
          </ListItemButton>
          <ListItemButton component={Link} to="/utilities/utms" sx={{ pl: 4 }}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="UTMs" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton component={Link} to={'/plans'}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Plans" />
      </ListItemButton>
      <ListItemButton onClick={handleWebsiteClick}>
        <ListItemIcon>
          <WebIcon />
        </ListItemIcon>
        <ListItemText primary="Website Builder" />
        {openWebsite ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openWebsite} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to="/websiteBuilder/sections" sx={{ pl: 4 }}>
            <ListItemIcon>
              <ViewModuleIcon />
            </ListItemIcon>
            <ListItemText primary="Sections" />
          </ListItemButton>
          <ListItemButton component={Link} to="/websiteBuilder/palettes" sx={{ pl: 4 }}>
            <ListItemIcon>
              <PaletteIcon />
            </ListItemIcon>
            <ListItemText primary="Palettes" />
          </ListItemButton>
        </List>
      </Collapse>
    </Fragment>
  );
};
